
* {
  min-height: 0;
  min-width: 0;
}
.slick-arrow{
    display:none !important;
    visibility:none !important;
}

.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    width:260px !important;
    height:397px !important;
  }
  
  .swiper-slide img {
    display: block;
    /* width: 100%;
    height: 100%; */
    object-fit: cover;
  }
  
  .swiper-button-next {
    height:10px !important;
    width:10px !important;
  }

  .swiper-scrollbar {
    background: #d6d6d6;
    cursor:pointer !important;
  }
  
  .swiper-container-horizontal > .swiper-scrollbar {
    border-radius: 2px !important;
    height: 3px !important;
    width: 200px !important;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .swiper-scrollbar-drag {
    background: #4581E1 !important;
  }

  .facilities-bg-color {
    background:linear-gradient(to right, white 0%, white 10%, #EFF6FF 10%, #EFF6FF 100%); 

  }


  /* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(192, 192, 192);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}